const app = {
    modal: {
        close: 'Schließen',
        ok: 'OK',
        cancel: 'Abbrechen',
    },
    products: {
        title: 'Produkte',
        new: 'Neuheiten',
        all: 'Alle Produkte',
        sizes: '',
        excludeVAT: 'exkl. MwST',
        startDesign: 'Design starten',
        searchResults: 'Suchergebnisse für &#8222;<strong>{query}</strong>&#8220;',
        noResults: 'Keine Ergebnisse gefunden',
        showInShop: 'Produkt im Store anzeigen',
        connectProduct: 'TiB Product Verbinden',
        connectProductId: 'Shopify Produkt ID',
    },
    tibProductConnect: {
        error: {
            title: 'Produkt konnte nicht verbunden werden',
            variantNotFound: 'Die folgenden Varianten konnten nicht gematched werden:\n',
        },
        success: {
            title: 'Produkt wurde erfolgreich verbunden',
        },
    },
    mainShop: {
        title: 'Mein Shop',
    },
    orders: {
        title: 'Bestellungen',
        personalized: 'Personalisierte Bestellungen',
        notEu: 'Nicht EU Bestellungen',
        personalizedShort: 'Personalisiert',
        notEUShort: 'Nicht EU',
        all: 'Alle',
        splittedOrders: 'Teil dieser Bestellung:',
    },
    myDesignLibrary: {
        title: 'Meine Designs',
        deleteConfirm: 'Willst du das Design wirklich löschen?',
    },
    resources: {
        title: 'Ressourcen',
        blog: 'Print-On-Demand Blog',
        whatsappChannel: 'WhatsApp Channel',
        productAndDeliverInformation: 'Produkt- und Lieferinformationen',
        productImages: 'Produkt-Bilder',
        helpAndFaqs: 'Hilfe & FAQs',
    },
    deliveryInformation: 'Lieferinformationen',
    account: {
        affiliateProgram: 'Affiliate Programm',
        title: 'Account',
        invoices: 'Rechnungen',
        settings: 'Einstellungen',
        logout: 'Abmelden',
        logoutMessage: 'Du wurdest abgemeldet, da es Probleme mit deinem Konto gibt.',
    },
    pagination: {
        prev: 'Zurück',
        next: 'Vor',
        further: 'Weiter',
        create: 'Erstellen',
    },
    search: {
        placeholder: 'Suche',
    },
    sort: {
        value: 'Sortierung',
    },
    filter: {
        label: 'Filter',
    },
    creditCard: {
        error: {
            title: 'Kreditkarte konnte nicht hinterlegt werden',
            tokenContent: 'Ungültige Kreditkarten-Informationen',
            backendContent: 'Fehler beim Hinterlegen der Daten',
        },
        success: {
            title: 'Erfolgreich gespeichert',
            content: 'Deine Kreditkarte wurde erfolgreich hinterlegt',
        },
    },
    welcomeBlock: {
        title: `Willkommen{username}`,
        suggest: 'Durchstöbere unser Produktportfolio und kreiere in weniger als 2 Minuten dein Print on Demand Produkt.',
        subtitle:
            "--- Tagesaktuelle Known Issues findet ihr <a target='_blank' rel='noopener noreferrer' href='https://help.marketprint.de/known-issues/'>hier</a>. ---<br><br>Das lang ersehnte <b>MarketPrint 2.0</b> Update ist endlich online! Wir haben das komplette Frontend geändert, um euch ein noch besseres Nutzererlebnis zu bieten.<br><br>Sollte es innerhalb der ersten zwei Wochen noch zu kleinen Problemen kommen, ruf uns gerne unter der Telefonnummer<b>+49 8261 7939101</b> an.<br>Oder hinterlass deine Meinung und Vorschläge für künftige Neuerungen unter: <b>feedback@marketconsultive.de</b><br><br>Wir wünschen dir viel Spaß mit dem neuen Design und sind gespannt von dir zu hören! Wir sind noch lange nicht am Ende und danken dir für deine Treue.<br><br>- Das komplette Team der <i>MarketConsultive GmbH</i><br><br>PS: Ein paar spannende Features, wie deine eigene Design-Bibliothek, werden in den nächsten Wochen für dich bereitgestellt! Schau auch mal auf <a target='_blank' rel='noopener noreferrer' href='https://www.marketprint.de'>www.marketprint.de</a> vorbei! Außerdem planen wir für das Wintergeschäft ein komplettes Produktportfolio von mindestens 20 (!) neuen Bestseller-Produkten, die innerhalb der nächsten Wochen und Monate online gehen.",
        cta: 'Jetzt Produkt kreieren',
    },
    productsIntro: {
        title: 'Unser Produktsortiment',
    },
    currentOrders: {
        title: `Aktuelle Bestellungen:`,
        more: 'Mehr anzeigen...',
    },
    currentOrdersModal: {
        shopifyId: 'Shopify ID',
        trackingNumber: 'Sendungsnummer(n)',
        moreInfo: 'Mehr Infos',
        paymentMethod: 'Zahlungsart',
        transactionNumber: 'Transaktionsnummer',
        ordered: 'Bestellt',
        inProcess: 'In Produktion',
        shipped: 'Versandt',
        cancelled: 'Storniert',
        refunded: 'Rückerstattet',
        yes: 'Ja',
        no: 'Nein',
        btnUploadDesigns: 'Upload Designs',
        btnReleaseForProduction: 'Zur Produktion freigeben',
        btnViewOrder: 'Bestellung anzeigen',
        btnUpdateCustomerData: 'Adresse aktualisieren',
        updateCustomerData: 'Kundendaten ändern',
        btnDownloadInvoice: 'Download Rechnung',
        textReleaseForProduction: 'Willst du diese Bestellung wirklich zur Produktion freigeben?',
        orderReady: 'Bestellung wurde zur Produktion freigegeben!',
        orderReadyErrorTitle: 'Bestellung konnte nicht zur Produktion freigegeben werden!',
        orderReadyError: 'Überprüfe die Designs auf Vollständigkeit!',
        btnUploadInvoices: 'Upload Rechnung',
        invoice: 'Rechnung',
        selectVariant: 'Wähle Variante',
        selectImage: 'Personalisiertes Design',
        confirmationUpload: 'Upload erfolgreich!',
        notPayed: 'Zahlung an MarketPrint fehlgeschlagen. Bitte in den Einstellungen erneut versuchen oder an den Support wenden.',
        noPreview: 'Momentan kein Vorschau für Tassen! \n Trotzdem kann das Design hochgeladen werden.',
        for: 'für',
        btnRetryPayment: 'Zahlung neu versuchen',
        uploaded: 'Designs hochgeladen',
        downloadInvoice: 'Rechnungs-Download',
        noInvoice: 'Rechnung nicht verfügbar',
        noMockups: 'Mockups nicht verfügbar für dieses Produkt',
        btnCancel: 'Bestellung stornieren',
        cancelReason: 'Grund für die Stornierung',
        textCancel: 'Soll diese Bestellung wirklich storniert werden?',
        orderCancelErrorTitle: 'Bestellung konnte nicht storniert werden',
        orderCancelled: 'Bestellung wurde storniert',
        orderCancelError: 'Die Bestellung ist möglicherweise bereits in Produktion oder versendet.',
    },
    checklist: {
        title: `Deine Checkliste`,
        subtitle: `Nur noch wenige Schritte um deinen Shop einzurichten!`,
        installApp: `MarketPrint-App installieren`,
        createProduct: `Produkt erstellen`,
        paymentMethod: `Zahlungsmethode auswählen`,
    },
    todoOrders: {
        title: `Deine TODO’s`,
        subtitle: `Vervollständige deine Bestellungen`,
        order: `Lade die Rechnung für die Bestellung <strong>#{id}</strong> hoch`,
    },
    sortOptions: {
        relevance: 'Relevanz',
        newArrivals: 'Neuankömmling',
        bestSelling: 'Meistverkauften',
        priceLowHigh: 'Preis Niedrig Hoch',
        priceHighLow: 'Preis Hoch Niedrig',
        ratings: 'Bewertung',
    },
    home: {
        bestseller: 'Aktuelle MarketPrint Bestseller',
        newProducts: 'Neue Produkte',
        shopConnectSuccess: {
            title: 'Shop Verbunden',
            content: 'Du hast deinen Shop erfolgreich mit MarketPrint verbunden.',
        },
        shopConnectError: {
            title: 'Shop Verbindungsfehler',
            content: 'Beim Verbinden deines Shops mit MarketPrint ist ein Fehler aufgetreten. Bitte versuche es später erneut.',
            alreadyConnected: 'Beim Verbinden deines Shops ist ein Fehler aufgetreten: Der Shop ist bereits mit einem anderen Account verbunden.',
        },
    },
    ordersIntro: {
        orders: 'Bestellübersicht',
        personalized: 'Personalisierte Bestellungen',
        notEu: 'Nicht EU Bestellungen',
    },
    design: {
        selectShop: 'Für welche Shop möchtest du ein Produkt erstellen?',
        selectShopTitle: 'Shop auswählen',
        front: 'Vorne',
        back: 'Hinten',
        left: 'Links',
        right: 'Rechts',
        colors: 'Farben',
        size: 'Größe',
        sizes: 'Größen',
        design: 'Design',
        countries: 'Länder',
        germany: 'Deutschland',
        austria: 'Österreich',
        switzerland: 'Schweiz',
        landArea: 'Land / Zone',
        alternativeDesign: 'Alternatives Design',
        alternativeDesignColors: 'Alternatives Design für folgende Farben',
        productDescription: 'Produktbeschreibung',
        downloadProductData: 'Produktdatenblatt und Mockups herunterladen',
        designNote: 'Hinweise zum Anlegen des Designs',
        sizeChart: 'Größentabelle',
        insertGraphic: 'Grafik einfügen',
        important: `<span>Wichtig:</span> Platziere dein Design innerhalb der grünen Außenmarkierung. Beim überschreiten der Linien wird dein Design nicht vollständig auf das Produkt gedruckt.`,
        productTitle: 'Produkttitel',
        productTitlePlaceholder: 'Titel hinzufügen',
        productDescriptionPlaceholder: 'Beschreibung hinzufügen',
        doubleSidedPrint: 'Mehrseitig bedruckt - bereits eingerechnet',
        purchasePriceVat: 'Einkaufspreis (exkl. MwSt)',
        purchasePrice19Vat: 'Einkaufspreis (inkl. 19% MwSt)',
        sellingPrice19VAt: 'Verkaufspreis (inkl. 19% MwSt)',
        profitMargin: 'Deine Gewinnmarge (Brutto)',
        price: 'Preis',
        shippingCostsOverview: 'Versandkostenübersicht',
        shippingPrice: 'Versandpreis',
        additionalPricePerProduct: 'Pro zusätzlichem Produkt in der Bestellung wird 1.00 € zusätzlich berechnet',
        addSizeChart: 'Größentabelle zum Shop hinzufügen',
        publishProduct: 'Produkt in Shopify veröffentlichen',
        chooseMockup: 'Wähle dein Mockup aus',
        titleLibrary: 'Deine Design-Bibliothek',
        addDesignLibrary: `Neues Design hochladen`,
        personalProduct: 'Dies ist ein personalisierbares Produkt',
        tibProductFront: 'Vorne Optimieren',
        tibProductBack: 'Hinten Optimieren',
        tibProductSleeveLeft: 'Ärmel Links Optimieren',
        tibProductSleeveRight: 'Ärmel Rechts Optimieren',
        tibProductLeft: 'Links Optimieren',
        tibProductRight: 'Rechts Optimieren',
        tibProductFrontLeft: 'Vorne Links Optimieren',
        tibProductFrontRight: 'Vorne Rechts Optimieren',
        tibProductBackLeft: 'Hinten Links Optimieren',
        tibProductBackRight: 'Hinten Rechts Optimieren',
        tibProductSideLeft: 'Seite Links Optimieren',
        tibProductSideRight: 'Seite Rechts Optimieren',
        tibProductAll: 'Alle Seiten Optimieren',
        quality: {
            good: 'Gut',
            normal: 'Mittel',
            bad: 'Schlecht',
        },
        productCreated: 'Produkt erstellt',
        productCreateError: 'Produkt konnte nicht erstellt werden',
        maxOne: 'Maximal eine Grafik',
        infoCanvas: `<span>Platzierung:</span> Vorderseite des Canvas innerhalb des dunkelgrauen Rahmens,
              seitliche
              Überlappung bis zur grünen Linie.`,
        infoPoster: `<span>Platzierung:</span> Platzierung des
              Designs bis zur äußeren grünen Linie. Hellgrauer
              Bereich wird bei Produktion abgeschnitten.`,
        infoGlas: `<span>Keine Platzierung:</span> Für Gravurarbeiten stellen wir <b>keine</b> Platzierung zur
                            Verfügung, bitte ändere deine Vektorgrafik entsprechend und lade das Bild anschließend
                            erneut hoch, um die Platzierung anzupassen.<br><br>
                      <span>Festes Seitenverhältnis:</span> Achte darauf, dass dein SVG die <b>Breite
                          {printMediumWidth} </b> und <b>Höhe {printMediumHeight}</b> hat.<br>
                            Alternativ setze Breite und Höhe so, dass sie das gleiche Seitenverhältnis haben wie <b> {printMediumAspectRatio} </b>.<br><br>
                         <span>Nur eine Design-Datei:</span> Es kann <b>nur eine</b> Vekorgrafik für das Design
                            verwendet werden.`,
        disabledStep2: `Um zum nächsten Schritt zu gelangen, müssen Sie wählen: Farbe, Größe und Bild hochladen`,
        disabledStep3: `Um zum nächsten Schritt zu gelangen, müssen Sie den Produktnamen, die Beschreibung und den Preis angeben`,
        uploadErrorMessageBeer:
            'SVG Seitenverhältnis stimmt nicht mit Seitenverhältnis des Printmediums (Breite/Höhe = 1.67) überein. Bitte versuche es nochmal mit einer passenden Datei!',
        uploadErrorMessageWine:
            'SVG Seitenverhältnis stimmt nicht mit Seitenverhältnis des Printmediums (Breite/Höhe = 1) überein Bitte versuche es nochmal mit einer passenden Datei!',
        uploadErrorTitle: 'Fehler bei Upload',
        sleeveRight: 'Ärmel Rechts',
        sleeveLeft: 'Ärmel Links',
        frontLeft: 'Vorne Links',
        frontRight: 'Vorne Rechts',
        backLeft: 'Hinten Links',
        backRight: 'Hinten Rechts',
        sideLeft: 'Seite Links',
        sideRight: 'Seite Rechts',
        combinedDesignTitle: 'Gleiches Design auf allen Seiten',
        combinedDesignDescription: 'Deaktiviere diese Option, wenn du unterschiedliche Designs für den linken und rechten Schuh verwenden möchtest.',
        designDownload: 'Design Download',
        addToShop: 'Hinzufügen zu Shop',
        sizeChartTooltip: 'Die Größentabelle zeigt deinen Kunden die verschiedenen Maße des Produkts.',
    },
    publishProduct: {
        title: 'Hinzufügen zu Shop',
    },
    accountSettings: {
        title: 'Einstellungen',
        shopSettings: 'Shop Einstellungen',
        noShops: 'Noch keine Shops hinzugefügt.',
        subtitle: 'Verwalte deine Profileinstellungen.',
        contactInfo: 'Kontaktdaten',
        paymentInfo: 'Zahlungsinformationen',
        email: 'E-Mail',
        tel: 'Telefon',
        billingAddress: 'Rechnungsadresse',
        senderAddress: 'Absenderadresse',
        paymentServiceProvider: 'Zahlungsdienstleister',
        billingAgreement: 'Abrechnungsvereinbarung',
        deposited: 'Hinterlegt',
        changePaymentMethod: 'Zahlungsmethode ändern',
        tryAgain: 'Nochmal versuchen',
        notifications: 'Benachrichtigungen',
        preferredLanguage: 'Sprache',
        preferredLanguageDescription:
            'Wenn du bei MarketPrint eingeloggt bist, ist dies die Sprache, die du siehst.<br/>Du kannst die Sprache jederzeit ändern.',
        productLanguage: 'Produkte im Shop Anlegen',
        productLanguageDescription: 'Hier änderst du die Sprache deines Produkts und die Maßeinheit der Größentabelle beim Import in deinen Shop.',
        apiToken: 'API Token',
        apiTokenDescription:
            'Hier kannst du ein API Token für verschiedene Services generieren. Nach der Erstellung des Tokens, kannst du es nur ein mal sehen. Bitte beware es sicher auf. Du kannst jederzeit ein neues Token generiern, was das alte Token invalidiert.',
        generate: 'Erstellen',
        regenerate: 'Neu Erstellen',
        popup: 'Bitte erlaube das PopUp Fenster, um deine Zahlungs-Daten zu hinterlegen',
        notificationsCheckbox: {
            failedPayment: 'Benachrichtigung erhalten, wenn Zahlungen fehlschlagen',
            personalizedDesign: 'Benachrichtigung erhalten, wenn ein personalisiertes Design hochgeladen werden muss',
            tips: 'Möchtest du Tipps und Infos von MarketPrint per E-Mail erhalten?',
        },
        editBillingAddress: 'Rechnungsadresse bearbeiten',
        address: {
            title: 'Anschrift',
            firstName: 'Vorname',
            lastName: 'Nachname',
            address1: 'Addresszeile 1',
            address2: 'Addresszeile 2',
            company: 'Firma',
            phone: 'Telefonnummer',
            email: 'E-Mail',
            name: 'Name / Firma',
            address1Street: 'Straße',
            address1Number: 'Hausnummer',
            city: 'Stadt',
            zip: 'PLZ',
            countryName: 'Land',
            validate: {
                zipWarning: {
                    title: 'Falsche PLZ!',
                    content: 'Die eingegebene Postleitzahl ist ungültig. Bitte überprüfe deine Angabe.',
                },
            },
            noChanges: 'Bitte nimm Änderungen vor bevor du speicherst',
            vatId: 'Umsatzsteuer-ID',
            chooseExisting: '--- Bestehende Adresse wählen ---',
        },
        language: 'Sprache:',
        units: 'Einheiten:',
        shoeSizes: 'Schuhgrößen:',
        editInvoiceAddressToasts: {
            success: {
                content: 'Rechnungsadresse erfolgreich gespeichert',
            },
            unavailable: {
                title: 'Warnung',
                content:
                    'Wir können deine Umsatzsteuer-ID aktuell leider nicht überprüfen, aber deine Adresse wurde gespeichert. Bitte versuche es später erneut.',
            },
            invalid: {
                content: 'Die eingegebene Umsatzsteuer-ID ist ungültig. Bitte überprüfe deine Angabe.',
            },
            format: {
                content: 'Die eingegebene Umsatzsteuer-ID hat ein falsches Format. Bitte überprüfe deine Angabe.',
            },
            germanVat: {
                title: 'Fehler',
                content: 'Eine deutsche Umsatzsteuer-ID ist nicht zulässig. Bitte überprüfe deine Angabe.',
            },
        },
        save: 'Speichern',
        editSenderAddress: 'Absenderadresse bearbeiten',
        general: 'Anschrift ändern',
        selectPayment: 'Bitte wählen',
        paypal: 'PayPal',
        creditCard: 'Kreditkarte',
        notDeposited: 'Nicht hinterlegt',
        deposit: 'Einzahlung tätigen',
        paymentError: 'Zahlungsmethode nicht verfügbar',
        tib: 'TeeInBlue',
        connectTibProductInfo: 'Verbinde deine TiB Produkte mit Marketprint',
        saveIntegrationApiInfosInfo: 'Speichere deine TiB API Informationen, um Versand Updates in TeeInBlue zu erhalten',
        webhookUrl: 'Webhook URL',
        stockAvailabilityTitle: 'Lagerverfügbarkeit',
        stockAvailabilityCheckbox: 'Lagerverfügbarkeit automatisch aktualisieren',
        stockAvailabilityDescription:
            'Ist diese Option aktiviert, werden deine Produkte automatisch als ausverkauft gesetzt, sobald es von unseren Lieferanten gerade nicht lieferbar ist oder das Produkt vom Hersteller abgesetzt worden ist.  <br> <br>Wenn diese Option nicht aktiviert ist, werden Produkte immer als verfügbar angezeigt, auch wenn das Produkt von unseren Lieferanten nicht mehr produziert wird oder nicht vorrätig ist.',
        updateEmail: {
            title: 'E-Mail-Adresse aktualisieren',
            placeholder: 'Neue E-Mail-Adresse',
            confirm: 'E-Mail-Adresse bestätigen',
            email: 'E-Mail-Adresse',
            button: 'E-Mail-Adresse aktualisieren',
            success: {
                title: 'Erfolg',
                message: 'Bitte folge den Anweisungen, die an deine E-Mail gesendet wurden, um deine neue E-Mail-Adresse zu bestätigen.',
            },
            error: {
                title: 'Fehler beim Aktualisieren der E-Mail-Adresse',
                emailExists: 'Die angegebene E-Mail-Adresse ist bereits registriert.',
                rateLimit: 'Zu viele Versuche. Bitte warte einen Moment und versuche es dann erneut.',
                unknown: 'Ein unbekannter Fehler ist aufgetreten. Bitte versuche es später erneut.',
            },
        },
        addShop: {
            title: 'Shop hinzufügen',
            reconnectTitle: 'Shop muss neu verbunden werden',
            howToLogin: 'Wo finde ich meine URL?',
            add: 'Hinzufügen',
        },
        disconnectShop: {
            title: 'Shop trennen',
            description:
                'Dies wird den Shop als getrennt markieren. Alles wird wie zuvor funktionieren, aber es ist dann möglich, den Shop mit einem anderen Konto zu verbinden. Wenn der Shop mit einem anderen Konto verbunden wird, werden alle relevanten Produkte und Designs für diesen Shop im neuen Konto dupliziert.',
            disconnect: 'Shop trennen',
            reconnect: 'Shop wieder verbinden',
            statusConnected: 'Status: <b style="color: var(--color-secondary)">Verbunden</b>',
            statusDisconnected: 'Status: <b style="color: var(--color-primary)">Getrennt</b>',
        },
    },
    shopSelector: {
        noShops: 'Keine Shops verfügbar',
        notConnected: '(nicht installiert)',
        notOnboarded: '(nicht onboarded)',
        disconnected: '(getrennt)',
        all: 'Alle Shops',
    },
    myProducts: {
        title: 'Meine Produkte',
    },
    noResults: {
        text: 'Es sind noch keine Bestellungen vorhanden',
        products: 'In dieser Kategorie sind noch keine Produkte verfügbar',
    },
    noShops: {
        connected: 'Derzeit sind keine Shops verbunden. Um einen Shop zu verbinden, gehe zu den',
        settings: 'Einstellungen',
    },
    shared: {
        yes: 'Bestätigen',
    },
    invoices: {
        title: 'Rechnungen',
        startDate: 'Anfangsdatum',
        endDate: 'Enddatum',
        shop: 'Shop',
        download: 'PDF Download',
        downloadCSV: 'CSV Download',
        annotation: 'Der Download kann einige Zeit in Anspruch nehmen',
        noResults: 'Keine Rechnungen in diesem Zeitraum vorhanden.',
    },
    login: {
        install1: `Melde dich<br>bei `,
        install2: ' an',
        shopUrl: 'Shopify Domain eingeben (SHOPNAME.myshopify.com):',
        redirect: 'Weiterleitung...',
        login: 'Anmelden',
        marketprint: 'MarketPrint',
        company: 'MarketPrint - ein MarketConsultive GmbH Unternehmen',
        companyAddress: 'Allgäuerstraße 20, 87719 Mindelheim, Deutschland',
        password: 'Passwort',
        registerInstead: 'Registrieren',
        error: {
            title: 'Login Fehler',
            rateLimit: 'Zu viele Login-Versuche. Bitte warte einen Moment und versuche es dann erneut.',
            emailNotConfirmed: 'Diese E-Mail-Adresse wurde noch nicht bestätigt.',
            invalidCredentials: 'Ungültige Anmeldedaten.',
            firstTimeApiCallFailed:
                'Dein Konto wurde erstellt, aber es gab einen internen Fehler. Bitte versuche dich anzumelden oder dich erneut zu registrieren.',
            unknown: 'Ein unbekannter Fehler ist aufgetreten. Bitte versuche es später erneut.',
        },
    },
    emailResend: {
        title: 'Bestätigungs-E-Mail erneut senden',
        success: {
            title: 'Erfolg',
            message: 'Die Bestätigungs-E-Mail wurde erneut gesendet.',
        },
        error: {
            title: 'Fehler beim erneuten Senden',
            rateLimit: 'Zu viele Versuche. Bitte warten Sie einen Moment und versuchen Sie es erneut.',
            unknown: 'Ein unbekannter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.',
        },
    },
    forgotPassword: {
        forgotPassword: 'Passwort vergessen?',
        enterEmail: 'Bitte geben Sie Ihre E-Mail-Adresse ein, um einen Passwort-Zurücksetzen-Link zu erhalten.',
        resetPassword: 'Passwort zurücksetzen',
        success: {
            title: 'Erfolgreich',
            message: 'Ein E-Mail wurde an Ihre E-Mail-Adresse mit einem Passwort-Zurücksetzen-Link gesendet.',
        },
        error: {
            title: 'Fehler beim Zurücksetzen des Passworts',
            unknown: 'Ein unbekannter Fehler ist aufgetreten. Bitte versuche es später erneut.',
        },
    },
    updatePassword: {
        success: {
            title: 'Erfolgreich',
            message: 'Ihr Passwort wurde erfolgreich aktualisiert.',
        },
        error: {
            title: 'Fehler beim Aktualisieren des Passworts',
            samePassword: 'Das Passwort sollte sich vom alten Passwort unterscheiden.',
        },
        confirm: 'Passwort Bestätigung',
        button: 'Passwort aktualisieren',
        placeholder: 'Neues Passwort',
        title: 'Passwort aktualisieren',
        nonceMessage: 'Ein Code wurde an Deine E-Mail-Adresse gesendet. Bitte gebe den Code unten ein, um fortzufahren.',
        noncePlaceholder: 'Einmaliger Code',
        nonceInvalid: 'Der angegebene Code ist ungültig.',
    },
    register: {
        register: 'Registrieren',
        loginInstead: 'Einloggen',
        error: {
            title: 'Registrierungsfehler',
            rateLimit: 'Zu viele Registrierungsversuche. Bitte warte einen Moment und versuche es dann erneut.',
            emailExists: 'Diese E-Mail-Adresse ist bereits registriert.',
            weakPassword:
                'Das eingegebene Passwort ist zu schwach. Das Passwort muss mindestens 8 Zeichen lang sein und einen Groß-/Kleinbuchstaben, eine Zahl und ein Sonderzeichen enthalten.',
            unknown: 'Ein unbekannter Fehler ist aufgetreten. Bitte versuche es später erneut.',
        },
        success: {
            title: 'Registrierung erfolgreich',
            message: 'Du hast dich erfolgreich registriert. Bitte überprüfe deine E-Mails, um deinen Account zu bestätigen.',
        },
    },
    onboarding: {
        title: 'Willkommen bei MarketPrint!',
        welcomeText: `Vielen Dank, dass Du unsere Print on Demand Dienstleistung nutzen willst!<br><br>
            Wir sind ein modernes & leidenschaftliches Unternehmen, welches eine All-in-One Print on Demand Leistung anbietet und alles dafür setzt, Shopify Online Händlern die beste und einfachste User Experience im Online Handel zu bieten.<br>
            Wir arbeiten kontinuierlich an unserer App und unserem Service, um das Geschäftsmodell Print on Demand für jeden zugänglich zu machen und Dir die Möglichkeit zu bieten, auf ein ständig wachsendes Produktportfolio und einen automatisierten Bestell-Prozess zurückzugreifen.<br><br>
            Wir haben immer ein offenes Ohr für Verbesserungsvorschläge bezüglich unserer Shopify App. Ruft uns hierfür gerne an oder schreibt unserem Support.<br><br>
            Viel Spaß mit der MarketPrint App und auf viele gute Verkäufe,<br>
            Team MarketPrint`,
        privacy:
            "Ich habe die <a href='https://marketprint.de/agb/' target='_blank'>AGB</a> und <a href='https://marketprint.de/datenschutzerklarung-marketprint-app/' target='_blank'>Datenschutzerklärung</a> gelesen und erkläre mich mit diesen einverstanden",
        emailNotifications: 'Ich möchte von MarketPrint über neue Produkte und Neuigkeiten per E-Mail informiert werden',
        formTitle: 'Ganz wichtig für den perfekten Start:',
        formText: `Damit Deine eingehenden Bestellungen automatisiert abgewickelt werden können, musst Du Deine Zahlungsmethode hinterlegen. Wir bieten Dir PayPal oder Kreditkarte als Option an und empfehlen Dir für einen reibungslosen Start Deine Zahlungsmethode & Rechnungsadresse jetzt zu hinterlegen.<br><br>
            Bitte fülle das Formular aus und wähle die für Dich geeignete Zahlungsmethode:`,
        billingAddress: 'Rechnungsadresse hinzufügen:',
        name: 'Name:',
        namePlaceholder: 'Name angeben',
        firstName: 'Vorname:',
        firstNamePlaceholder: 'Vorname angeben',
        company: 'Name/ Firma:',
        companyPlaceholder: 'Name / Firma angeben',
        addressStreet: 'Straße:',
        addressStreetPlaceholder: 'Straße hinzufügen',
        addressNumber: 'Hausnummer:',
        addressNumberPlaceholder: 'Hausnummer hinzufügen',
        postcode: 'PLZ:',
        postcodePlaceholder: 'PLZ hinzufügen',
        country: 'Land:',
        countryPlaceholder: 'Land auswählen',
        paymentMethod: 'Zahlungsdienstleister auswählen:',
        thankYouTitle: 'Du hast es geschafft! 🎉',
        thankYouText: `Du hast alles erledigt und bist bereit für die Produktkreierung und den Verkauf deiner eigenen Marke.<br>
            Lass uns gemeinsam Dein allererstes Produkt erstellen. Du wirst sehen, wie leicht und schnell das geht.<br><br>
            Probiere es gleich aus, klicke einfach auf den Button und lass uns direkt mit einem unserer Bestseller Dein erstes Produkt kreieren.`,
        thankYouNext: 'Jetzt loslegen!',
        thankYouBack: 'Überspringen',
        city: 'Stadt:',
        cityPlaceholder: 'Stadt hinzufügen',
        error: {
            title: 'Kontoinformationen konnten nicht gespeichert werden',
            backendContent: 'Fehler beim Speichern der Daten',
        },
    },
    success: {
        title: 'Erfolgreich',
    },
    error: {
        title: 'Fehler',
        content: 'Ein Fehler ist aufgetreten',
    },
    header: {
        notifications: 'Benachrichtigungen',
    },
    countries: {
        Deutschland: 'Deutschland',
        Österreich: 'Österreich',
        Schweiz: 'Schweiz',
        Andorra: 'Andorra',
        Belgien: 'Belgien',
        Bulgarien: 'Bulgarien',
        Dänemark: 'Dänemark',
        Estland: 'Estland',
        Finnland: 'Finnland',
        Frankreich: 'Frankreich',
        Griechenland: 'Griechenland',
        Irland: 'Irland',
        Italien: 'Italien',
        Kroatien: 'Kroatien',
        Lettland: 'Lettland',
        Luxemburg: 'Luxemburg',
        Monaco: 'Monaco',
        Niederlande: 'Niederlande',
        Norwegen: 'Norwegen',
        Polen: 'Polen',
        Portugal: 'Portugal',
        'San Marino': 'San Marino',
        Schweden: 'Schweden',
        Slowakei: 'Slowakei',
        Slowenien: 'Slowenien',
        Spanien: 'Spanien',
        'Tschechische Republik': 'Tschechische Republik',
        Ungarn: 'Ungarn',
        Vatikanstadt: 'Vatikanstadt',
        'Vereinigtes Königreich': 'Vereinigtes Königreich',
        Zypern: 'Zypern',
        Kosovo: 'Kosovo',
        Litauen: 'Litauen',
        Malta: 'Malta',
        Rumänien: 'Rumänien',
        Albanien: 'Albanien',
        'Bosnien und Herzegowina': 'Bosnien und Herzegowina',
        Liechtenstein: 'Liechtenstein',
        Mazedonien: 'Mazedonien',
        Moldau: 'Moldau',
        Montenegro: 'Montenegro',
        Serbien: 'Serbien',
        'Vereinigte Staaten': 'Vereinigte Staaten',
        'Zone 1': 'Zone 1',
        'Zone 2': 'Zone 2',
        'Zone 3': 'Zone 3',
    },
    codeToCountry: {
        AE: 'Vereinigte Arabische Emirate',
        AT: 'Österreich',
        BE: 'Belgien',
        BG: 'Bulgarien',
        CY: 'Zypern',
        CZ: 'Tschechien',
        DE: 'Deutschland',
        DK: 'Dänemark',
        EE: 'Estland',
        EL: 'Griechenland',
        ES: 'Spanien',
        FI: 'Finnland',
        FR: 'Frankreich',
        GR: 'Griechenland',
        HR: 'Kroatien',
        HU: 'Ungarn',
        IE: 'Irland',
        IT: 'Italien',
        LT: 'Litauen',
        LU: 'Luxemburg',
        LV: 'Lettland',
        MT: 'Malta',
        NL: 'Niederlande',
        PL: 'Polen',
        PT: 'Portugal',
        RO: 'Rumänien',
        SE: 'Schweden',
        SI: 'Slowenien',
        SK: 'Slowakei',
        GB: 'Vereinigtes Königreich',
        CH: 'Schweiz',
        NO: 'Norwegen',
        US: 'Vereinigte Staaten',
    },
    shippingTable: {
        canvas: 'Canvas',
        poster: 'Poster',
        basePrice: 'Grundpreis',
        perAdditionalProduct: 'Je Weiteres Product',
    },
    time: {
        today: 'Heute',
        nDaysAgo: 'Vor {n} Tagen',
        '1DayAgo': 'Vor 1 Tag',
    },
    unitSystems: {
        metric: 'Metrisch',
        imperial: 'Imperial',
    },
    shoeSizeSystem: {
        eu: 'EU',
        us: 'US',
        uk: 'UK',
    },
    affiliateProgram: {
        title: 'Affiliate Programm',
        activeShopsLastYear: 'Shops jünger als 1 Jahr',
        allShops: 'Alle Shops',
        payouts: 'Auszahlungen',
        payoutDate: 'Auszahlungsdatum',
        payoutAmount: 'Auszahlungsbetrag',
        payoutStatus: 'Auszahlungsstatus',
        btnDownloadPayout: 'Auszahlung herunterladen',
        payoutInfo: 'Auszahlungsinformationen',
        paymentServiceProvider: 'Zahlungsdienstleister',
        billingAgreement: 'Abrechnungsvereinbarung',
        deposited: 'Hinterlegt',
        addNewPaymentMethod: 'Neue Zahlungsmethode hinzufügen',
        changePaymentMethod: 'Zahlungsmethode aktualisieren',
        tryAgain: 'Nochmal versuchen',
        popup: 'Bitte erlaube das PopUp Fenster, um deine Zahlungsdaten zu hinterlegen',
        selectPayment: 'Bitte wählen Sie',
        paypal: 'PayPal',
        creditCard: 'Kreditkarte',
        billingAddress: 'Rechnungsadresse',
        paymentStatus: {
            open: 'Offen',
            invoice_upload_pending: 'Rechnung hochladen',
            invoice_being_verified: 'Rechnung wird überprüft',
            payment_pending: 'Zahlung ausstehend',
            paid: 'Bezahlt',
            failed: 'Fehlgeschlagen',
        },
    },
};

export default app;
